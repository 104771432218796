#ancillary-tiles {

  .tile-row {
    @include flexbox;
    justify-content: center;
  }

  .tile {
    background: $ancillary-hub-tile-background-color;
    border: 1px solid $brand-primary;
    border-radius: 0;
    min-height: $ancillary-hub-tile-height;
    justify-content: center;
    margin: $ancillary-hub-tile-margin;
    width: $ancillary-hub-tile-width;

    .tile-aligner {
      .tile-body {
        p {
          margin: 0 $padding-base-horizontal;
        }

        .tile-product-name {
          font-size: $ancillary-hub-tile-product-font-size;
          &.selected {
            display: inline;
            color: $brand-primary;
          }
        }

        .icon-product__selected {
          color: $ancillary-hub-tile-success-icon-color;
        }
        .tile-lead-pricing {
          font-size: $ancillary-hub-tile-lead-pricing-font-size;
        }

      }

      .tile-spinner {
        padding: $ancillary-hub-tile-spinner-padding;
        .icon-spinner {
          @include verticallyMiddle();
        }
      }
    }

    &.selected {
      background-color: rgba($brand-primary, 0.12);
    }
  }
}
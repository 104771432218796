.modal-content {
  max-height: 90vh;
}

.modal-open {
  margin-right: 0 !important;
}

.modal-dialog {
  min-width: 100%;
}

.modal-body {
  max-height: 60vh;
}

%wide-modal {
  .modal-dialog {
    min-width: 100%;
  }
}

.main-contents-container .modal h1 {
  border-bottom: 0;
  margin: 0;
  padding-bottom: 0;
}

.modal-full {
  &.has-iframe .modal-body iframe {
    height: 100vh;
  }

  .modal.in .modal-dialog {
    padding: 0;
  }

  &.modal-alternate {
    .modal-header {
      background: $modal-alt-header-bg;
      border-color: $modal-alt-border-color;
      color: dynamicColor($modal-alt-header-bg);
    }

    h1 {
      color: dynamicColor($modal-alt-header-bg);
    }

    .modal-body {
      background: $modal-alt-body-bg;
    }

    .modal-content {
      background-color: $modal-alt-content-bg;
    }

    .modal-footer {
      background: $modal-alt-footer-bg;
      border-color: $modal-alt-border-color;
    }

    .close {
      color: #fff;
    }
  }

  .modal-body {
    max-height: fit-content;
    padding: 0;
  }

  .modal-content {
    height: 100vh;
    max-height: 100vh;
  }

  .close {
    @include opacity(1);
  }

  &.modal-footer-hide {
    iframe,
    .modal-body {
      max-height: 100vh;
    }

    iframe {
      height: 100%;
    }
  }
}
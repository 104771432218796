@import "_travel-credit-alert.base";

.section-travel-credit {
  margin-top: $padding-large-vertical * 2;
  background: none !important; // Used important because this should be treated as a component with defined styles
  padding: 0 !important;

  .panel-price {
    .panel-body-top {
      font-size: $font-size-base_handheld;
    }
    .panel-body-bottom {
      font-size: $font-size-large_handheld;
    }
  }
}

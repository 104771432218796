.shopping-cart-details {
  @extend %wide-modal;

  .no-selection {
    padding: $padding-base-vertical 0;
  }

  .modal-header {
    background: $shopping-cart-header-background-color;
    color: $shopping-cart-header-color;
    a {
      color: $link-color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    h3, .h3 {
      color: $shopping-cart-header-color;
    }
  }

  .modal-body {
    background: $shopping-cart-background-color;
    a {
      color: $link-color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }

    }
  }

  .modal-footer {
    background: $shopping-cart-background-color;
    margin-top: 0;
    a {
      color: $link-color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  section {
    background: $shopping-cart-section-background-color;
    margin: $padding-base-vertical 0;
    padding: $padding-large-vertical $padding-large-horizontal $padding-large-horizontal $padding-large-horizontal;

    &.visited {
      background: $shopping-cart-section-visited-background-color;
    }

    .section-header {
      border-bottom: 1px solid $modal-body-bg;
      font-size: $font-size-large;
      font-weight: $text-strong-font-weight;
      padding-bottom: $padding-large-vertical;
      margin-bottom: $padding-large-vertical;
      @include flexbox;
      align-items: center;

      h2 {
        flex-grow: 1;
      }

      .heading {
        font-weight: $shopping-cart-section-header-heading-font-weight;
        text-transform: uppercase;

        &.itinerary {
          color: $shopping-cart-section-header-trip-itinerary-color;

          &:hover {

            .icon {
              text-decoration: none;
            }
          }

          .icon {
            color: $shopping-cart-section-header-trip-itinerary-color;
          }
        }

        .icon {
          &:before {
            text-align: center;
            width: 20px;
          }
        }
      }

      &.trip-itinerary {
        border-bottom: 0;
        color: $shopping-cart-section-header-trip-itinerary-color;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &.empty {
      background: $shopping-cart-section-background-color;
      padding-bottom: $padding-large-vertical;

      .section-header {
        border-bottom: 0;
        font-weight: normal;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    .section-body {
      padding: 0;

      table {
        border-collapse: collapse;
        background-color: transparent;
        margin-bottom: 0;
        .shopping-cart-total {
          td {
            padding: $padding-base-vertical 0;
          }
          border-top: 1px solid $modal-body-bg;
        }
      }

      .air-leg.row {
        margin-bottom: $padding-base-horizontal;

        .text-strong {
          font-weight: $shopping-cart-section-header-heading-font-weight;
        }
      }

      p {
        margin-bottom: floor($padding-small-vertical / 2);
      }
    }

    .product-details-component {
      border-top: 1px solid $modal-body-bg;
      margin-top: $padding-large-vertical;
      padding-top: $padding-large-vertical;

      .hr-text {
        border-top: 1px solid $modal-body-bg;

        span {
          background: $shopping-cart-section-visited-background-color;
          color: $text-color;
        }
      }
    }
  }

  .total-price {
    background: transparent;
    margin-top: $padding-base-horizontal;
  }
}
#aws-chat {
  width: calc(min(55px, 10.5vh));
  height: calc(min(55px, 10.5vh));
  padding: 0;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  outline: none;
  transition: background 0.5s ease;
  position: fixed;
  z-index: 999999999;
  bottom: 20px;
  right: 20px;
  font-size: $font-size-h3;
}

.aws-chat-section {
  display: flex;
  flex-flow: column;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999999999;
  background-color: $body-bg;
  width: 100%;
  height: 100%;

  .component {
    display: flex;
    flex-flow: column;
    flex: 1;

    .component-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 50px;
    }
    .component-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  #aws-chat-iframe {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  #aws-chat-collapse {
    border-radius: 0;
    font-size: $font-size-h3;
  }
}
#dashcart,
#your-itinerary {

  .section-xsell {
    header {
      background: $dashcart-product-header-background-color-xsell;

      h2 {
        color: $dashcart-product-header-color-xsell;
      }
    }

    .section-body {
      background: $product-body-bg-color-xsell;
      border-color: $product-body-border-color-xsell;
      color: $product-body-text-color-xsell;

      > div {
        border-bottom: 1px solid $product-body-border-color-xsell;

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }

    .product-xsell {
      padding: $padding-large-vertical 0;
    }
  }
}

#dashcart-modal,
#shopping-cart-modal {

  section > header,
  .section-xsell .section-body {
    margin-left: -$modal-inner-padding;
    margin-right: -$modal-inner-padding;
    padding-left: $modal-inner-padding;
    padding-right: $modal-inner-padding;
  }

  .dashcart-header,
  .component {

    hr {
      margin: $padding-large-vertical -20px;
    }

  }

  .dashcart-body {
    padding-bottom: $padding-large-vertical * 3;
  }

  .section-body {
    padding-left: 0;
    padding-right: 0;
  }

  .dashcart-footer {
    // By design, there is no header here, but we're using section > header to achieve full width of modal container.
    > header {
      padding: 0;
      border-bottom: 0;
    }
  }

  .component:last-of-type hr {
    display: none;
  }

  .component-body {
    margin: $padding-large-vertical*2 0;
  }

  .hr-text {
    margin-top: $padding-large-vertical*2 !important;
  }

}

@import "_progress-bar.base";

.progress-bar-component {
  width: $progress-bar-width-handheld;
  height: $progress-bar-height-handheld;

  .bar {
    background-size: $progress-bar-height-handheld $progress-bar-height-handheld;
  }
}

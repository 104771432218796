// BAGS SECTION
// ----------------

#bags-selection {

  header {
    background-color: white;
    border: none;

    p {
      color: $gray-light;
    }
  }

  .product {
    .bag-selection-product {
      @extend .no-padding;
    }
  }

  .air-leg-bag-option {

    .air-leg-bag-unavailable {
      background: white;
      border-bottom: 1px solid $gray-light;
      border-top: 1px solid $gray-light;
      box-sizing: content-box;
      color: $brand-warning;
      font-style: italic;
      margin-bottom: 0;
      padding: $padding-large-vertical $ancillary-bag-name-base-padding;
      text-transform: lowercase;

      &:first-letter {
        text-transform: capitalize;
      }
    }

    .leg-title {
      font-weight: $text-strong-font-weight;
      margin: 0;
      padding: $padding-large-vertical $ancillary-bag-name-base-padding;
    }

    .traveler-list {
      background: white;
      border-top: 1px solid $gray-light;
      padding-left: 0;

      .traveler-item {
        border-bottom: 1px solid $gray-light;
        box-sizing: content-box;
        margin-bottom: 0;

        .traveler-item-bag {
          align-items: center;
          display: flex;
          flex-flow: row;
          justify-content: space-between;
          padding: $ancillary-bag-traveler-item-padding $ancillary-bag-name-base-padding;

          .traveler-name {
            padding-right: $padding-small-horizontal;

            &.highlight {
              font-weight: $text-strong-font-weight;
            }
          }

          .membership-tier {
            font-style: italic;
            text-transform: lowercase;

            &:first-letter {
              text-transform: capitalize;
            }
          }
        }

        .bag-options, .bag-details {
          overflow: hidden;
        }

        .bag-options div:last-child .bag-item {
          border: none;
        }

        .bag-options {
          .bag-item {
            border-bottom: 1px solid $almost-white;

            &.selected {
              background-color: rgba($brand-primary, .15);
            }

            .bag-details {
              align-items: center;
              display: flex;
              flex-flow: row;
              justify-content: space-between;

              .ancillary-price-from-label,
              .price-strikethrough {
                line-height: 1.2;
              }

              .bag-number-stepper {
                height: calc(#{$line-height-computed} * 2.5);
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .icon {
                color: $brand-primary;
              }

              .no-bag-description {
                i {
                  display: none;
                }
                span {
                  color: $text-color;
                }
              }

              span {
                display: block;
              }

              > :first-child {
                padding-left: 0;
              }

              > :last-child {
                padding-right: 0;
              }

              .bag-name {
                color: $brand-primary;

                span {
                  display: table;
                }

                i {
                  padding: 0 $ancillary-bag-name-arrow-horizontal-padding;
                }
              }
            }

            .bags-included {
              padding-left: $ancillary-bag-dimensions-margin-right-handheld;
            }

            .bag-dimensions .content {
              p {
                margin: 0;
              }
              color: $gray-medium;
              margin: $ancillary-bag-dimensions-margin-top $ancillary-bag-dimensions-margin-left $ancillary-bag-dimensions-margin-bottom $ancillary-bag-dimensions-margin-right_handheld;
            }

          }
        }
      }
    }
  }

  .product-footer {
    padding-top: $padding-large-vertical;

    .row {
      margin: 0;
    }

    .apply-to-all {
      color: $brand-primary;
      margin-bottom: $ancillary-bag-apply-to-all-margin-bottom;
      padding: 0 $ancillary-bag-name-base-padding;
    }

    .clear-all {
      padding: 0 $ancillary-bag-name-base-padding;
    }
  }
}
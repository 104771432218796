.trip-summary {
  color: $gray;
  background-color: #fff;
  font-weight: 400;
  padding-left: ($padding-small-vertical * 3);
  padding-top: ($padding-small-vertical * 4);
  padding-bottom: ($padding-small-vertical * 5);
  border-bottom: 1px solid $gray-light;
  margin-bottom: 30px;

  p {
    margin: 0px;
  }

  .summary-title {
    font-size: 14px;
    color: $gray-dark;
    padding-bottom: $padding-small-vertical;
  }

  .icon {
    .icon-product__flight {
      color: $trip-summary-plane-color;
      font-size: 13px;
      bottom: 4px;
    }

    padding-left: 14px;
    width: 1.3em;
    height: 1.3em;
    display: inline-block;
    vertical-align: 120%;
  }

  .information {
    display: inline-block;

    .departure-location,
    .arrival-location,
    .departure-date,
    .arrival-date {
      font-size: 14px;
      color: $gray-medium;
    }

    .to-translation {
      text-transform: lowercase;
      font-size: 14px;
      color: $gray-medium;
    }
  }

}
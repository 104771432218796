// IMPORTANT
// This file is used for ONLY "handheld" case; it is used in place of app-main.scss

//
// Files must be loaded in the following order:
// 1) base/application level styles
// 2) page level styles
// 3) component level styles
// 4) responsive styles
//

$assetsPath:  '//cdn.switchfly.com/static/4c72c35/resources/shopping/images/application';

@import       "../../vendor/sass-bootstrap-3.0.16/sass/sass-bootstrap-shopping",
              "app-icons/_icon-font",
              "components/_site-toolbar";
              // SG Note: We don't need bootstrap-helpers (shared) in this app.

// APP UI COMPONENT STYLES (These MUST be defined before BASE STYLES (which are overrides for our base/library styles))
@import       "ui-components/_buttons",
              "ui-components/_button-groups",
              "ui-components/_modals",
              "ui-components/_modals.handheld",
              "ui-components/_tags",
              "ui-components/_tags.handheld",
              "ui-components/_badges",
              "ui-components/_safekey-modal";

// -----------------------------------------------------------------
// SHOPPING APP OVERRIDES
// -----------------------------------------------------------------

// BASE STYLES
@import       "app-global.handheld";

// PAGES
@import       "pages/_404",
              "pages/_search-results",
              "pages/_manage-booking",
              "pages/_checkout";

// SKELETON SCREENS
@import       "skeleton-screens/_skeleton-hotel-detail",
              "skeleton-screens/_skeleton-activity-detail";

// COMPONENTS
@import       "ui-components/_flexbox",
              "ui-components/_cssgrid",
              "ui-components/_grid-info",
              "ui-components/_stack",
              "components/_header-compact",
              "components/_search_form",
              "components/_kards",
              "components/_media",
              "components/_products.handheld",
              "components/_product-filters",
              "components/_shopping-cart-details.handheld",
              //"components/_branded-flights",
              //"components/_compare-fare-types-modal",
              //"components/_date-carousel",
              "components/_air-flight-matrix-leg-based",
              "components/_dashcart.handheld",
              "components/_dashbar.handheld",
              "components/_seatmap.handheld",
              "components/_seat-map-legend.handheld",
              "components/_seatmap-preview.handheld",
              "components/_bags.handheld",
              "components/_ancillary-modules.handheld",
              //"components/_promotions",
              "components/_hotel-images",
              "components/_hotel-images.handheld",
              "components/_itinerary.handheld",
              "components/_typeahead",
              "components/_bing-map",
              "components/_centinel-widget",
              //"components/_floating-cart-element.scss",
              "components/_add-product-check",
              "components/_confirmation",
              "components/_social-media-links",
              "components/_trip_summary.handheld",
              "components/_wells",
              "components/_pagination",
              "components/_pointsAllocation",
              "components/_filter-slider",
              "components/_datepicker",
              "../../vendor/intl-tel-input/src/css/intlTelInput",
              "components/_international-phone-numbers",
              "components/_ancillary-hub.handheld",
              "components/_number-stepper",
              "components/_card",
              "components/_branded-fare-details-panel.handheld",
              "components/_air-flight-matrix.handheld",
              "components/_air-layovers",
              "components/_progress-bar.handheld",
              "components/_travel-credit-alert.handheld",
              "components/_panel-drawer",
              "components/_air-pnr-result-row-branded-fare",
              "components/_hotel-similar-product",
              "components/_web-chat.handheld";

//PRINT
@import       "app-print";
@import "_air-flight-matrix.base";

.air-flight-matrix {
  &:not(.roundtrip) .air-flight-matrix-navigation-bar {
    width: 100% !important;
  }

  .air-flight-matrix-table-wrapper {
    align-self: center;

    table {
      /* Headers */
      .header {
        background-color: $flight-matrix-headers-background-color-handheld;
        color: $flight-matrix-headers-text-handheld;
        font-size: $flight-matrix-headers-font-size_handheld;

        &.selected {
          border-color: $flight-matrix-border-color;
        }

        &:hover {
          background-color: $flight-matrix-headers-background-color-handheld;
        }
      }
    }

  }

  .air-flight-matrix-lowest-price-legend-wrapper {
    margin-bottom: 1em;
  }

  table td button:disabled,
  table td button span {
    font-size: 3.5vw;
  }

  .air-flight-matrix-description {
    padding: ($padding-small-vertical * 3) 0;
    border-top: 1px solid $gray-lighter;
    margin-top: 0;
  }

  .air-flight-matrix-table-wrapper {
    margin: ($padding-large-vertical * 2) 0;
  }

}

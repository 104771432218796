.itinerary-container {

  .hidden-cart-summary {
    display: none !important;
  }

  .dashcart-header,
  .dashcart-footer {
    padding: $section-common-padding_handheld;
  }

  .dashcart-summary > .section-body {
    @extend .no-padding;
  }

}

#checkout-form-container {
  .components-body {
    margin: 10px 0;
  }
}
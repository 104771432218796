// Ancillary Modules
// -----------------------------
// Ancillary seat maps (#seat-selection) styles are in _seatmap.base.scss

.ancillary-page .page-header {
  margin-top: 0px;

  .hub-page-title {
    text-align: center;

    h1 {
      color: $ancillary-title-color-handheld;
      font-size: $ancillary-title-font-size-handheld;
      margin-top: $ancillary-title-margin-top-handheld;
    }

    h2 {
      color: $ancillary-subtitle-color-handheld;
      font-size: $ancillary-subtitle-font-size-handheld;
      margin-top: $ancillary-subtitle-margin-top-handheld;
    }
  }

}

.ancillary-page {
  padding-top: ($padding-base-vertical * 2);
  padding-bottom: ($padding-base-vertical * 2);
  background: $ancillary-section-body-bg-color-handheld;

  .btn-continue-hub {
    display: block;
    position: fixed;
    width: $ancillary-hub-continue-button-width-handheld;
    padding-left: $ancillary-hub-continue-button-padding-left-handheld;
    padding-right: $ancillary-hub-continue-button-padding-right-handheld;
    bottom: $ancillary-hub-continue-button-bottom-handheld;
    border-radius: $ancillary-hub-continue-button-border-radius-handheld;
    height: $ancillary-hub-continue-button-height-handheld;
    font-size: $ancillary-hub-continue-button-font-size-handheld;
    z-index: $ancillary-hub-continue-button-z-index-handheld;
  }

  .ancillary-module {
    margin-bottom: ($padding-base-vertical * 2);
  }
}


.main-contents-container #hotel-preview-results {
  header .btn-link {
    display: block;
  }

  .card-deck {
    align-items: stretch;
    flex-flow: column;
  }

  .card-block {
    flex-flow: column;
    flex-direction: column;
    padding: $padding-large-vertical;
  }

  .hotel-preview-selected {
    border: 1px solid $product-border-color-selected;

    .container{
      padding: ($padding-large-vertical*2);
    }

    h3{
      margin-top:0;
    }

    h4{
      display: inline;
    }

    .selected-label{
      padding-bottom: ($padding-small-vertical*3);
    }

    .selected-hotel-info{
      background: transparent;
      margin-top:($padding-large-vertical*2);

      td:nth-child(odd) {
        min-width: ($padding-small-horizontal * 10)
      }

      .room-title {
        vertical-align: text-top;
      }
    }
    img {
      display: none;
    }
  }

}
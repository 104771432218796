.dashbar-handheld {
  padding: 0 ($padding_small-vertical * 2);
  color: $dashbar-handheld-text-color;
  background-color: $dashbar-handheld-background-color;
  height: 36px;

  @include flexbox;
  align-items: center;

  .dashbar-icon-left div {
    @include flexbox;
    align-items: center;
  }

  .icon-scroll--prev-item {
    font-size: 36px;
  }

  .text-center {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .dashbar-go-back,
  .dashbar-trip-summary {
    padding-left: $padding-small-horizontal
  }
}
.product-body .product-padding {
  padding: $padding-small-vertical;
}

#air-details-container {
  .product + .product {
    margin-top: $padding-large-vertical * 2;
  }

  .product {
    margin-right: $padding-large-horizontal / 2;
  }

  .product-header,
  .product-body {
    padding: 0 0 0 $padding-large-horizontal;
    border: 0;
  }
  .component {
    border-bottom: 1px dotted $product-border-color;
    padding: ($padding-large-vertical * 2) 0;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  .component-header .col-xs-10 {
    padding-bottom: $padding-large-vertical;
    border-bottom: 1px solid $product-border-color;
  }

  .component-body {
    padding-top: $padding-large-vertical;
  }
}

#flight-details-modal-body {

  .product-header {
    border: 0;
  }

  .product-body {
    border: 0;
    padding: 3em 0 0;
  }

  .component {
    margin-bottom: 3em;
  }

  .component-body {
    margin: 0 -8px;
  }
}

.products-list {

  @extend .clearfix;

  .price-container {
    background-color: inherit;
    text-align: $product-price-container-text-alignment_handheld !important; // !important necessary to properly override .text-* class
  }

  .product {
    border-radius: $product-kard-border-radius_handheld;

    .product-body {
      @include box-shadow($shadow-md);
      border-radius: $product-kard-border-radius_handheld;
    }

    .kard {
      margin: $padding-large-vertical 0;
      border-radius: $product-kard-border-radius_handheld;
    }

    .product-name {
      font-weight: $text-strong-font-weight;
    }
  }

  &.products-rooms {
    border-top: 1px solid $product-border-color;
    background: $products-list-bg-color_handheld;
    margin-bottom: 0;

    li.product {
      .image-btn img {
        height: 200px;
        object-fit: cover;
        width: 100%;
      }

      &.room-selected {
        background: $product-room-selected-bg-color_handheld;
        border: 3px solid $product-border-color-selected_handheld;
        border-radius: $product-border-radius-selected_handheld;
      }
    }
  }
}

.products-rooms + .products-rooms {
  margin-top: -($line-height-computed / 2);
}

#room-options {
  .section-body {
    padding-top: 0;
  }

  legend {
    margin-bottom: 0;
    background: $products-list-bg-color_handheld;

    h3 {
      padding: $section-header-padding_handheld;
    }
  }

  .modal-body legend {
    background: transparent;

    h3 {
      font-size: $font-size-large;
      margin: 0 0 $padding-large-vertical;
      padding: 0;
    }
  }
}

.product {
  .baggage-included {
    color: $baggage-info-included-icon-color;
  }

  .baggage-not-included {
    color: $baggage-info-not-included-icon-color;
  }

  .no-results {
    padding: 10px;
  }

  @import "_expandable-container";

  .product-featured-deal {
    background: $product-featured-background-color;
    color: $product-featured-name-text-color;
    font: $product-featured-font-weight $product-featured-deal-name-font-size_handheld $product-featured-font-family;
    padding: $padding-large-vertical $padding-small-horizontal;

    &.selected {
      font-size: $product-featured-deal-selected-outbound-font-size_handheld;
    }

    ~ .product-body {
      border: $product-featured-deal-border;
    }
  }

  &.product-selected {
    .product-body {
      background: $product-body-bg-color-selected_handheld;
      border: 3px solid $product-border-color-selected_handheld;
    }
  }

  &.product-selected {
    .product-body,
    .product-deal {
      border-color: $product-border-color-selected;
    }
  }

  &.product-selected {
    .product-note {
      .hr-text {
        @include hrText($product-body-bg-color-selected_handheld, currentColor);
      }
    }
  }

  &.product-loading .product-body {
    background: $product-body-bg-color-loading;

    .icon-collapsible--closed:before {
      @extend .icon-spin;
      content: "\f110";
    }
  }

  &.product-loading,
  &.product-selected {

    .select-info {
      background: $product-body-bg-color;
      color: $text-color;
    }
  }

  .product-loading .text-info {
    color: $product-loading-text-info-color;
  }

  .price-footer {
    padding: 15px 0 0;
  }

  /* product media */

  .product-header,
  .product-footer {
    @include clearfix;
  }

  .product-body,
  .product-deal {
    @extend .clearfix;
    border: $product-body-border;
    background: $product-body-bg-color;
    padding: $padding-large-horizontal;

    .product-notification {
      border: 1px solid $product-notification-border-color;
      background: $product-notification-body-bg-color;
      color: $product-notification-body-text-color;
      margin: 5px 0 10px 0;
      padding: 5px 10px 5px 10px;
    }
  }

  .product-detail-notification {
    border: 1px solid $product-notification-border-color;
    background: $product-notification-body-bg-color;
    color: $product-notification-body-text-color;
    margin: 5px 10px 10px 10px;
    padding: 5px 10px 5px 10px;
  }

  .product-deal {
    border: 0;
  }

  .price-total:not(.price-strikethrough) span {
    @extend .text-strong;
    font-size: $font-size-large;
  }

  .map-container {
    margin-bottom: $padding-large-vertical;
  }

  .price-container {
    margin: $padding-base-vertical 0;
    text-align: $product-price-container-text-alignment_handheld !important; // !important necessary to properly override .text-* class
  }

  .product-detail .price-container + div.additional-fees:before {
    content: none;
  }

  div.additional-fees + div.product-benefits-heading:before {
    @extend .p-sm;
    content: '';
    border-top: 1px solid $product-border-color;
    display: block;
  }

  .padding-bottom-fees {
    padding-bottom: 10px;
  }

  .price-primary {
    color: $product-primary-price-value-color !important;
    font-size: $product-primary-price-value-font-size_handheld !important;
    font-weight: $product-primary-price-value-font-weight !important;
    line-height: $line-height-xs !important;

    .price-primary-label {
      color: $product-primary-price-label-color !important;
      font-size: $product-primary-price-label-font-size_handheld !important;
      font-weight: $product-primary-price-label-font-weight !important;
    }

    .or {
      color: $product-primary-price-value-color !important;
    }
  }

  .price-secondary {
    color: $product-secondary-price-color !important;
    font-size: $product-secondary-price-font-size_handheld !important;
    font-weight: $product-secondary-price-font-weight !important;
    line-height: $line-height-xs !important;

    .or {
      color: $product-secondary-price-color !important;
    }
  }

  // DEALS and PROMOTIONS
  .promotion-notation {
    padding: $promotion-notation-padding;
    background: $promotion-notation-bg-color_handheld;
    color: $promotion-notation-text-color_handheld;
  }

  .product-deal, //rate-deal, exclusive dealsß
  .product-featured {
    padding: $product-deal-padding-vertical $product-deal-padding-horizontal;
    background-color: $product-deal-bg-color;
    color: $product-deal-text-color;

    @if $product-deal-content == 'none' {
      padding-right: $product-deal-padding-horizontal;
    } @else {
      padding-right: 0;
    }
  }

  .special-program {
    padding: $padding-base-vertical $padding-base-horizontal;
    border-radius: $product-deal-special-program-border-radius;
    height: $product-deal-special-program-banner-height;
  }

  &.product-featured {
    .product-body {
      border-color: $product-featured-border-color;
    }

    h2 {
      @extend .col-padding;
      padding-top: $padding-base-vertical;
      background: $product-featured-bg-color;
      color: $product-featured-text-color;
    }
  }

  .rate-deal {
    background: $rate-deal-bg-color;
    color: $rate-deal-text-color;
    line-height: $rate-deal-line-height_handheld;
    border-radius: $rate-deal-border-radius;

    &::before {
      content: '';
      border-left: 10px solid transparent;
      position: absolute;
      bottom: -10px;
      left: 0;
      border-top: 10px solid darken($rate-deal-bg-color, 10%);
    }

    &.no-fold {
      max-width: $rate-deal-max-width_handheld;
      font-size: $rate-deal-font-size_handheld;

      &::before,
      &::after {
        content: none;
      }
    }

    &.loyalty-deal {
      background: $loyalty-deal-bg-color;
      color: $loyalty-deal-text-color;

      &:after {
        border-top-color: $loyalty-deal-bg-color;
      }
    }
  }

  .product-deal-container {
    position: relative;
    overflow: hidden;

    .product-deal {
      text-align: left;
      position: relative;
      margin-right: 15px;
      display: inline-block;
      border-radius: $product-deal-border-radius;

      &:after {
        content: $product-deal-content;
        float: right;
        position: absolute;
        top: 0;
        right: -14px;
        border-top: 50px solid;
        border-right: 15px solid transparent;
      }

      &.loyalty-deal {
        background: $loyalty-deal-bg-color;
        color: $loyalty-deal-text-color;
      }
    }

    .client-approved {
      background: $product-deal-client-approved-bg-color_handheld;
      color: $product-deal-client-approved-text-color_handheld;

      &:after {
        border-top-color: $product-deal-client-approved-bg-color_handheld;
      }
    }



    .exclusive-deal {
      background: $product-deal-exclusive-bg-color_handheld;
      color: $product-deal-exclusive-text-color_handheld;

      &:after {
        border-top-color: $product-deal-exclusive-bg-color_handheld;
      }
    }

    .client-exclusive {
      background: $product-deal-client-exclusive-bg-color;
      color: $product-deal-client-exclusive-text-color;

      &:after {
        border-top-color: $product-deal-client-exclusive-bg-color;
      }
    }

    .eligible-for-hotel-sale {
      background: $product-deal-hotel-sale-bg-color;
      color: $product-deal-hotel-sale-text-color;

      &:after {
        border-top-color: $product-deal-hotel-sale-bg-color;
      }
    }

    .members-only-rate {
      background: $product-deal-members-only-rate-bg-color;
      color: $product-deal-members-only-rate-text-color;

      &:after {
        border-top-color: $product-deal-members-only-rate-bg-color;
      }
    }

    .loyalty-deal {
      background: $loyalty-deal-bg-color;
      color: $loyalty-deal-text-color;

      &:after {
        border-top-color: $loyalty-deal-bg-color;
      }
    }
  }

  .product-deal {
    &.special-program {
      background-color: $product-deal-special-program-bg-color;
      color: $product-deal-special-program-text-color;
      height: $product-deal-special-program-banner-height_handheld;

      + .product-body {
        color: $product-deal-special-program-body-text-color;
        background-color: $product-deal-special-program-body-bg-color;

        .price-strikethrough {
          text-decoration-color: $strikethrough-text-decoration-color;
        }

        //!important to override app-global
        .rating .star::before {
          color: $product-deal-special-program-star-rating-color;
        }
      }

      &.special-program-thc + .product-body {

        div,
        h2,
        .product-locked span {
          background-color: $product-deal-special-program-thc-body-bg-color;
          color: $product-deal-special-program-thc-body-text-color;
        }

        //important to override app-global
        .rating .star::before {
          color: $product-deal-special-program-thc-star-rating-color;
        }
      }
    }
  }

  .products-cars & {
    .product-body.special-program-body {
      background-color: $product-deal-special-program-car-background-color;
      div,
      span { //setting back to text-color if desktop uses white text on special program hotels
        color: $product-deal-special-program-car-body-text-color;
      }
    }
  }

  .special-amenities {
    padding: $section-common-padding;

    //If there's a payment section below amenities
    + div {
      border-top: $product-promo-border;
      padding-top: $padding-small-vertical;
    }

    ul {
      padding: 0;
    }
  }

  .col-interactive {
    border-left: 1px solid $base-border-color;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background: $product-col-interactive-background-color;
    color: $product-col-interactive-text-color;

    &.selected {
      background: $product-col-interactive-selected-background-color;
      color: $product-col-interactive-selected-text-color;
    }
  }

  .product-supplier {
    color: $text-muted;
    font-size: $font-size-small;
    padding: 0 $padding-large-horizontal;
  }

  .product-details-list {

    .component-section-body > ul div > {
      // This div selector is for Ember
      li {
        border-top: 1px solid $base-border-color;
        border-bottom: 1px solid $product-body-bg-color;
      }
    }

    .component-header .h3 {
      padding-top: ($line-height-computed / 2);
    }

    .component-body {
      margin: 0;

      &.row {
        margin: 0 -8px;
      }

      h3 {
        margin: 0;
      }

      .trip-advisor-icon {
        padding: 2px;
      }
    }

    &.room-selection-section {
      .component-footer {
        padding-right: $padding-large-horizontal;
      }
    }

  }

  .product-note {
    .hr-text {
      @include hrText($product-body-bg-color, currentColor);
    }
  }

  .product-additional-options-container {
    background-color: $product-additional-options-drawer-bg-color;
    color: dynamicColor($product-additional-options-drawer-bg-color);
    padding-bottom: $padding-large-vertical * 2;

    .product-additional-options-container-header {
      padding: $padding-large-vertical $padding-small-horizontal;
      padding-bottom: 0;

      > div:first-child {
        font-size: $product-additional-options-panel-title-font-size-handheld;
        color: $gray-dark;
      }
    }

    .product-additional-options-panel {
      @extend .panel;
      margin: $padding-large-vertical $padding-small-horizontal;

      .product-additional-options-card-title {
        font-size: $product-additional-options-card-title-font-size-handheld;
        color: $product-additional-options-card-title-color_handheld;
        margin-bottom: $padding-large-vertical;
        padding-top: $padding-large-vertical;
        padding-bottom: ($padding-large-vertical) * 2;
        border-bottom: 1px solid $gray-light;
      }

      .branded-fares-details-list {
        margin-bottom: $padding-large-vertical * 1.5;

        > li:not(:last-child) {
          margin-bottom: $padding-large-vertical * 2
        }
      }

      .product-additional-options-panel-footer {
        border-top: 1px solid $base-border-color;
        padding-top: $padding-large-vertical * 1.5;

        .expandable-section {
          > div:first-child {
            margin: ($padding-large-vertical * 2) 0;
          }
        }
      }

      .product-additional-options-panel-nested-footer {
        @extend .product-additional-options-panel-footer;
        padding-top: $padding-large-vertical;
        margin-bottom: $padding-small-vertical;
      }
    }
  }

  //Product benefits on hotel details (e.g. Program hotel benefits, FHR, THC)
  .product-benefits {
    background-color: $product-benefits-background-color;
    color: $product-deal-special-program-body-text-color;
    padding: $padding-small-vertical * 3;

    .btn-link {
      text-decoration: $product-benefits-info-link-decoration;
    }

    .info-link {
      text-align: $product-benefits-info-link-alignment_handheld;
    }

    button {
      color: $product-deal-special-program-body-text-color;
    }
  }
}

.banner-tag-container {
  border-top: $product-promo-border;
  border-bottom: $product-promo-border;
  padding: $padding-large-vertical*2 0;
  margin: $padding-large-vertical 0 $padding-large-vertical*2;

  img {
    width: 48px;
    height: 48px;
  }
}

.trip-advisor li {
  flex-shrink: 0;

  &:last-of-type {
    flex-shrink: 1;
  }
}

.trip-advisor.trip-advisor-margin {
  margin-left: -8px;
  margin-top: 6px
}

.trip-advisor-link-disabled, .trip-advisor-link-disabled:hover, .trip-advisor-link-disabled:active, .trip-advisor-link-disabled:visited, .trip-advisor-link-disabled:focus {
  color: inherit;
  text-decoration: inherit;
}

.hotel-images > .section-body {
  @extend .no-padding;
}

@media (max-width: 600px) {
  #room-filters,
  [id^="room-filters-"] {
    .flex-group {
      display: block;
      > *:first-of-type {
        display: flex;
        justify-content: space-between;
      }
      .visible-tablet-inline-block {
        display: block;
      }
    }
    h3 {
      display: inline-block;
      margin-bottom: $padding-base-vertical !important;
    }
  }
}

#hotel-user-reviews li {
  border-top: 1px solid $product-border-color;
  padding: $padding-base-vertical 0;
}

.product-detail + div:before,
.product-detail .price-container + div:before {
  @extend .p-sm;
  content: '';
  border-top: 1px solid $product-border-color;
  display: block;
}

.product-detail .price-container + div.loyalty-deal-message-container:before {
  border: none;
}

.product-detail .exceptional-value {
  text-decoration: none;
  text-transform: uppercase;

  i.icon {
    padding-bottom: 3px;
  }
}

.special-program-fhr + .product-body {
  div,
  span,
  h2 {
    color: $product-deal-special-program-body-text-color;
  }

  .exceptional-value {
    color: $product-deal-special-program-body-text-color;
    padding-bottom: 5px;

    i.icon {
      padding-bottom: 3px;
    }
  }
}

.special-program-thc + .product-body {
  .product-detail .exceptional-value .icon-container {
    color: $link-color !important;
    padding-bottom: 5px;

    i.icon {
      padding-bottom: 3px;
    }
  }
}

.product-deal {
  &.included-flag {
    background: $product-deal-included-flag-background-color;
    color: $product-deal-included-flag-text-color;
    width: 100%;
    text-align: center;
  }
}

.product-image-placeholder {
  background-color: $gray-lighter;
  height: 100px;
  width: 100%;
}

.included-product-message {
  color: $brand-success;
  font-weight: $text-strong-font-weight;
}

// Air Matrix

#grouped-products .enhanced-air,
#grouped-products .enhanced-air .product-groups > button {
  position: relative;
  font-family: $font-family-base;
  letter-spacing: normal;

  .text-strong:not(.icon) {
    font-family: $btn-font-family;
  }

  &.product-selected,
  &.product-group-selected {
    border: 3px solid $brand-success;
    margin-top: 2em;

    .product-body {
      background: #fff;
    }

    .product-indicator,
    .product-group-indicator {
      background: $brand-success !important;
      color: dynamicColor($brand-success);
      height: 2em;
      line-height: 2em;
      padding: 0 8px;
      top: -2em;
      left: -3px;
      width: calc(100% + 6px);
    }

    &.active {
      border: $product-group-fare-cell-border-width solid $product-group-fare-cell-border-color;
      margin-top: calc(2em + 2px);

      .product-indicator {
        width: calc(100% + 2px);
        left: -1px;
      }
    }
  }

  &.product-group-selected {
    margin-top: calc(2em + 15px);
  }

  .product-indicator,
  .product-group-indicator {
    position: absolute;
    top: -1px;
    left: -1px;
    height: 6px;
    width: calc(100% + 2px);
  }
}

.air-matrix .product {
  .leg-price,
  .lead-price {
    .price-total {
      color: $brand-primary;
    }
  }

  .leg-price,
  .fare-price {
    .price-total,
    .price-total span {
      font-size: $flight-matrix-total-price-font-size_handheld;
    }
  }

  .toggle-flight-details {
    font-size: $font-size-small_handheld;
  }
}

.branded-fare-cabin-details {
  font-size: $font-size-base;
  color: $gray;
}
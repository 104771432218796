// Global styles for ONLY Handheld case
// Extends app-global.base.scss
@import "app-global.base";

body,
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input,
.btn-text-input {
  font-size: $font-size-base_handheld !important;
  // Marking !important because these have to be at least 16px for iOS proper input zoom levels
}

//changes the text selection color back to black
::selection {
  background: $almost-white;
  color: $text-color;
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  margin: 6px 0 0;
}

body > div {
  overflow: hidden;
  // Necessary for layout overflow issues.
}

h1, .h1 {
  font-size: $font-size-h1_handheld;
  margin: $padding-large-vertical 0;
}

h2, .h2 {
  font-size: $font-size-h2_handheld;
}

h3, .h3 {
  font-size: $font-size-h3_handheld;
}

h4, .h4 {
  font-size: $font-size-h4_handheld;
}

.text-small {
  font-size: $font-size-small_handheld;
}

.text-large,
.btn-lg {
  font-size: $font-size-large_handheld;
}

.block-hh {
  display: block;
}

.time-format {
  font-size: $font-size-xlarge_handheld;
  line-height: $font-size-xlarge_handheld;

  span {
    font-size: $font-size-base_handheld;
  }
}

.page-content {
  padding-left: 0;
  padding-right: 0;
}

#site-header {
  margin-bottom: 0;
  //Adding this since site-header z-index gets overriden by a bootstrap class navbar.
  z-index: initial;

  .logo-primary {
    min-height: $site-header-logo-primary-min-height_handheld;
    min-width: 100%;
  }

  .loyalty-discount .btn-group,
  .dropdown-menu {
    width: 95vw;
    max-width: 800px;
  }

  .block-hh .dropdown-menu > ul > li > a {
    white-space: normal;
  }

  .header-loyalty-nav {
    .menu-item {
      border-bottom: none;
      margin-bottom: 0;
    }
    > a {
      border: none;
    }

    .loyalty-action {
      display: none; //hides button next to loyalty info
    }
  }

  .header-brand-row {
    display: flex;
    align-items: center;

    .header-brand-container {
      flex: 1;
    }

    .loyalty-action {
      display: flex;
    }
  }
}

#site-footer {
  background: $site-footer-background-color_handheld;
  border-top: $site-footer-border-top-width_handheld solid $site-footer-border-top-color;
  padding-top: $site-footer-padding-top;
  color: $site-footer-text-color;

  a {
    color: $site-footer-link-color;
    &:hover,
    &:active,
    &:focus {
      color: $site-footer-link-hover-color;
    }
  }
}

.page-content .page-body {
  overflow: visible;
  margin-bottom: $page-body-margin-bottom_handheld;
}

#search-form-container {
  min-height: $dashboard-search-form-min-height_handheld;

  .section-body:first-of-type {
    padding-top: 0;

    .form-group {
      margin-bottom: $padding-large-vertical * 1.5;
    }
  }
}

.page-search-form .page-header {
  margin-bottom: 0;
  padding: 0 $padding-small-horizontal;

  .text-right {
    // Set equal spacing between recent search and h2 header
    margin-bottom: $h2-margin-vertical;
  }
}

.page-body .page-header-toolbar {
  padding: $padding-base-vertical 0;
  background: $subheader-toolbar-row-background-color_handheld;
  color: $subheader-toolbar-row-text-color_handheld;

  .container:nth-child(2) {
    background-color: $subheader-toolbar-row-2-background-color_handheld;
  }

  a,
  .btn-link {
    color: $subheader-toolbar-row-anchor-color_handheld;
  }

  .btn {
    border-radius: $btn-border-radius;
  }
}

.pagination-lg {
  @include pagination-size($padding-large-vertical, $padding-large-vertical, $font-size-base, $border-radius-base);
  // Default pagination-lg sizing doesn't work well, adjusting sizing down to fit better.
}

ul.pagination > li > a {
  width: $pagination-size_handheld;
  height: $pagination-size_handheld;
}

.iframed-contents {
  overflow-x: hidden;
  word-break: break-word;
}

.iframe-seamless-container {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  max-height: 30vh;
}

.main-contents-container {
  padding-bottom: $padding-large-vertical * 2;

  .main-contents-container-aside {
    section > header {
      border: none;
      border-bottom: $section-header-border-top-bottom-width_handheld solid $section-header-border-top-bottom-color_handheld;
    }
  }

  section {
    > header {
      background: $section-header-background-color_handheld;
      padding: $section-header-padding_handheld;
      border-top: $section-header-border-top-bottom-width_handheld solid $section-header-border-top-bottom-color_handheld;
      border-bottom: $section-header-border-top-bottom-width_handheld solid $section-header-border-top-bottom-color_handheld;
    }

    .section-body {
      padding: $section-common-padding_handheld;

      &.section-body-full-width {
        padding-left: 0;
        padding-right: 0;

        .section-padding {
          padding-left: $padding-large-horizontal !important;
          padding-right: $padding-large-horizontal !important;
        }
      }
    }

    &.marketing-promotion-groups {
      .section-body {
        background: $marketing-promotion-groups-bg-color_handheld;
      }
    }
  }
}

.tag-group {
  text-align: $tag-group-text-alignment_handheld;

  > span {
    margin-left: $padding-base-vertical;
  }
}

.panel-body,
div.well {
  padding: $section-common-padding_handheld;
}

.panel {
  border-left: 0;
  border-right: 0;
}

.content-container {
  padding: 0 $padding-large-horizontal;
}

.tooltip .tooltip-inner {
  max-width: $tooltip-max-width_handheld;
}

.container-md {
  padding: 0 $page-area-padded-sm;
}

// Search Home Marketing Hero Messaging
// --------------------------------------------------
.hero-message {
  background-color: $hero-message-bg-color_handheld;
  color: $hero-message-text-color_handheld;
  padding: $padding-large-vertical $padding-large-horizontal;

  &.background-transparent {
    text-shadow: none;
    color: $hero-message-text-color-transparent_handheld;
    padding: 0;

    > .container {
      padding: 0;
    }
  }

  .hero-title {
    font-size: $hero-message-header-font-size_handheld;
  }
}

// Targets larger tablets (e.g. Nexus 10) (800px width)
// --------------------------------------------------
@media (max-width: 800px) {

  .financial .table-container > .col-sm-5,
  .financial .table-container > .col-sm-6 {
    width: 100%;
    padding-left: 0;
  }
  .financial .table-container > .col-sm-6 {

    .or {
      left: 50% !important;
      top: -15px;
      // Positions horizontally between 2 tables, which are now full width
    }
  }
}

.media-heading {
  margin-bottom: 0;
}

.products-list.media-list {
  background: $products-list-bg-color_handheld;
}

.previously-selected-legs.products-list.media-list {
  background: $product-body-bg-color;
  .product-selected {
    background: $product-body-bg-color-selected_handheld;
  }
}

.rating {
  font-size: 1em;

  span.star {
    font-size: 100%;
  }
}

.price-info {
  margin-bottom: $padding-large-vertical;
}

.price-strikethrough,
.price-strikethrough * { // asterisk to also target inline-blocked child elements
  font-size: $font-size-base_handheld; //reset when it's the price-primary
}

.air-leg-journey-summary {

  > div {
    &:nth-of-type(1) {
      grid-column-start: 1;
    }

    &:nth-of-type(2) {
      align-self: flex-end;
    }

    &:last-of-type {
      justify-self: end;
    }
  }
}

@supports (display: grid) {
  .air-leg-journey-summary {
    // requires 3 child divs. The center div will grow horizontally filling the space left by the 2 other divs.
    display: grid;
    grid-template-columns: auto 1fr auto;
  }
}

@supports not (display: grid) {
  .air-leg-journey-summary {
    @extend .clearfix;
    display: flex;

    > div {
      flex-grow: 1;
    }
  }
}

.air-result-row-flight-card {

  .flight-card-header {
    margin-bottom: $padding-large-vertical * 1.5;

    .flight-card-header-logo {
      width: 30px;
      height: 30px;
      padding: 1px; // Since icons are 28x28 complete with padding
      margin-right: $padding-small-horizontal;
    }
  }

  .air-legs > .component-section > .component-section-body {
    padding-bottom: $padding-large-vertical * 2;

    .hr-sm {
      margin-top: $padding-small-vertical !important;
      margin-bottom: $padding-large-vertical * 3 !important;
    }
  }

  .time-format {
    font-weight: $text-strong-font-weight;
  }

  .air-leg-journey-summary {
    margin-bottom: $padding-large-vertical * 2;
  }
}

.price-container {
  line-height: $line-height-computed;
}

.product .price-container .price-total {
  font-size: $font-size-base_handheld;
}

.map-container .MicrosoftMap {
  border: 0;
}

#booking-form {
  .section-body {
    [class*=col-] {
      width: 100%;
      display: block;
    }

    .inline-col {
      .col-xs-4 {
        width: 33.33333%;
      }

      .col-xs-6 {
        width: 50%;
      }
    }
  }

  .component-section {
    h3 {
      margin-bottom: 0;
    }
  }
}

#purchase-button {
  @extend .btn-block;
}

div#checkout-form-container,
div#traveler-info-container {
  padding: $checkout-form-container-padding_handheld;
}

#checkout-form-container .payment-supplier-logo li {
  width: 30%;
}

.border-align-right:before {
  content: '';
  border-top: 1px solid $product-border-color;
  display: block;
  margin-left: $grid-gutter-width;
  margin-right: -$grid-gutter-width;
}

// Targets xs-handheld (350px width) and below (also in app-responsive.scss)
// --------------------------------------------------
@media (max-width: 350px) {
  .input-daterange > [class*=col-] {
    width: 100%;
    // Makes date + time inputs single line, full width
  }
}

#component-breakdown,
#tax-breakdown,
#air-price-breakdown,
#canadian-tax-breakdown {
  .financial {
    &.table-bordered {
      > thead {
        tr,
        th,
        td {
          border-left: 0;
          border-bottom-width: 1px;
        }
      }

      > tbody {
        > tr > td {
          border-left: 0;
        }

        tr:last-of-type td {
          border-bottom: 0;
        }
      }

      td,
      th {
        &:last-of-type {
          border-right: 0;
        }
      }
    }

    th,
    td {
      display: table-cell;
    }

    td:first-of-type {
      width: 40%;
    }
  }
}

.page-body .financial .table {
  th, td {
    display: block;
    text-align: left;
  }

  .col-cash > span {
    width: 100%;
  }

  .total {
    th {
      border-bottom: 0;
    }

    td {
      border-top: 0;
    }
  }
}

#checkout-form-container {
  #pricing-summary {
    .table-container {
      .col-sm-6 {
        .table {
          margin-top: $padding-large-vertical * 3;

          .or {
            top: -24px !important;
            left: 5px !important;
            text-transform: none;
          }
        }
      }
    }
  }

  .payment {
    .has-icons {
      //aligns icons with label when they're stacked next to in input
      label {
        display: grid;
        grid-template-columns: 20px auto;
      }

      .payment-option-icon {
        grid-column-start: 2;
        justify-self: initial;
        background-position: left;
      }
    }

    .payment-details-form .btn-icon-uncommon {
      display: block;
      width: 100%;
    }
  }
}

#checkout-form-container,
#confirmation-page-container {
  #pricing-summary {
    header {
      background: transparent;
      padding: $section-header-padding_handheld;
      border-top: 0;
      border-bottom: 0;

      h2 .heading,
      h2 .total-price {
        display: block;
      }
    }

    .table-container {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: ($padding-large-vertical / 2);
      background: $price-table-bg-color_handheld;
      border: 1px solid $base-border-color;
      padding: $price-table-padding_handheld;
      border-radius: $price-table-border-radius;

      .table {
        background: $price-table-bg-color_handheld;
        margin-bottom: 0;

        th,
        td {
          padding: $price-table-cell-padding_handheld;
        }

        .col .currency-value,
        .col .currency-symbol,
        .col .plus,
        .col .points,
        .col .shortfall {
          width: auto;
        }

        .total {
          padding: $padding-large-vertical 0 0;
          border-top: 1px $price-table-total-border-color_handheld $price-table-total-border-style_handheld;

          td,
          th {
            border-color: $price-table-total-border-color_handheld;
            border-bottom: 0;
            border-top: 0;
          }
        }

        caption {
          border-bottom: 1px $price-table-total-border-color_handheld solid;
        }

        tr {
          margin: 10px 0;
          display: block;
        }

        td {
          font-weight: normal;
        }

        th {
          font-weight: bolder;
        }
      }
    }

    #discount {
      .row {
        margin: 0;

      }
    }
  }
}

// Targets tablet (768px width) and BELOW (should include handheld)
// --------------------------------------------------
@media (max-width: $screen-sm) {
  .hidden-tablet-down {
    display: none !important;
  }
}

// Daily room rates tables
.daily-room-rates-table {
  &.two-columns-room-rates-table > tr > td:first-child {
    padding-left: 0;
  }
}

pre {
  margin: 0;
  font-family: $font-family-base;
  font-size: $font-size-base_handheld;
}

// Scrollable tabs

.nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  & > li {
    float: none;
    display: inline-block;
  }
}

.nav-tabs::-webkit-scrollbar {
  width: 0 !important;
}

.navbar-nav > li > label {
  padding: $nav-link-padding;

  .select-dropdown:after {
    bottom: 0;
  }

  select {
    margin-bottom: 0;
  }
}

.navbar-default .navbar-nav {
  > li > a {
    &:focus {
      color: $navbar-default-link-color;
    }
  }
}

#client-header-navbar {
  .btn-link {
    color: $navbar-default-link-color_handheld;
  }

  #customer-service-ad {
    padding: $btn-padding-vertical $btn-padding-horizontal;
    background-color: $site-header-customer-service-bg-color_handheld;
    border-radius: $border-radius-base;
    margin: $btn-padding-vertical 0;
  }
}

.additional-fees {
  font-size: 13px;
}

.product-summary-page-body {
  background-color: $product-summary-page-body-bg-color_handheld;

  .table-container {
    padding: $price-table-padding_handheld;
    background-color: $product-summary-price-table-bg-color_handheld;

    > h2 {
      margin-top: 0;
    }

    .table {
      background-color: $product-summary-price-table-bg-color_handheld;
      color: $product-summary-price-table-text-color_handheld;

      th {
        font-weight: normal;
      }

      tr {
        padding-bottom: $padding-large-vertical;
        display: block;
      }

      .pricing-table-total {
        th {
          border-top: 1px solid $price-table-total-border-color;
          padding-top: $padding-large-vertical;
        }
      }
    }
  }

  .content-container {
    background-color: #fff;
    padding-top: $padding-large-horizontal;
    .flight-summary-continue-button {
      margin: $padding-large-horizontal 0 $padding-large-horizontal 0;
    }
  }
}

.products-list {
  padding: 8px; // Use .col spacing var
}

#grouped-products {
  .product {
    margin-top: 0;
  }

  .product-body {
    padding: $padding-small-vertical*3 $padding-small-horizontal $padding-small-horizontal;
    border-radius: $product-kard-border-radius_handheld;
  }

  .product-groups > button {
    border-radius: 0;
    margin-top: 15px;
    border: 1px solid #ccc;
    background: #fff;

    .h4 {
      color: #333;
    }
  }
}

// Loyalty promotion banner on checkout and confirmation
.loyalty-deal-container {
  .loyalty-deal {
    margin: $padding-large-vertical 0;

    .loyalty-deal-text {
      display: inline-block;
      background: $loyalty-deal-bg-color;
      padding: $padding-small-vertical $padding-large-horizontal;
      color: $loyalty-deal-text-color;
    }
  }
}

div.datepicker-calendar table.datepicker-grid {
  table-layout: $datepicker-table-layout_handheld;
}

.text-break-word {
  word-break: break-word;
}
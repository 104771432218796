@import "_seatmap.base";

.seat-selection-component {
  header {
    p.h4 {
      color: $gray-light;
    }
  }

  margin-bottom: $padding-base-vertical * 2;

}

.seat-map-flight-header {
  &.affix,
  &.affix .content {
    padding: 0;
  }

  .flight-info {
    background: $almost-white;
    padding: $padding-large-vertical $padding-small-horizontal;
  }
}

.seat-map-flight-container, #seat-map-legend{
  .seat{
    &.selected {
      font-size: calc(#{$font-size-small_handheld} * 0.87);
    }
  }
}


section.seat-map-container{
  margin-bottom: 12px;
  padding: 0;

  .seat-summary-continue-button {
    padding: $padding-small-horizontal * 2 0;
  }

  .seat-air-segments {
    .route-separator{
      text-transform: lowercase;
    }
  }
}

.ancillary-page
.seat-map-container {
  padding: 0;

  .seating-summary {
    border: none;
    padding: $padding-small-horizontal * 3 0;
  }
}

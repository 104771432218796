.slideshow-container {
  width: 100%;

  .images-count {
    color: #fff;
    background: $btn-image-label-opacity;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    right: 20px;
    padding: 5px;
    border-radius: $border-radius-base;
  }
}

.splide__slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  height: 300px !important; // Overrides inline styles from splide - Should match hotel_images_handheld.js mainCarousel fixedHeight : '300px',
}

.splide--fade > .splide__track > .splide__list > .splide__slide {
  background-color: $gray-dark !important;
}

.splide__track {
  overflow: hidden;
}
// Seat Map Styles
// -----------------------------
// Note: There are 3 distinction Seat Map style sets in here:
// .air-seating selects the seat map on checkout
// .seat-selection-preview selects the seat map on flight results (List view)

// List view and air details
// -----------------------------
.seat-selection-preview {

  #seat-map-modal {
    @extend %wide-modal;

    h3 {
      margin-top: $line-height-computed;
      margin-bottom: ($line-height-computed / 2);
    }
  }

  #seat-map-modal .row {
    margin-left: 0;
  }

  #seat-map-modal .modal-body {
    padding-bottom: 0;
  }

  #seat-map-modal .loading {
    margin-top: 15%;
    display: block;
    text-align: center;
  }

  #seat-map-modal .loading:before {
    left: 48%;
  }

  .map-body {
    padding: 0 15px;
  }

  .seat-map-container,
  .seat-map-unavailable-container {
    overflow-y: auto;
    height: 325px;
    margin-right: -15px;
  }

  .seat-map-flight-container{
    .seat{
      &.btn{
        cursor: auto;
        pointer-events: none;
      }
      &.btn:hover {
        background-color: $btn-default-bg-color-hover;
      }
    }
  }

  .map-body .span4 > div {
    margin-bottom: $line-height-computed;
  }

  .map-header {
    border-bottom: 1px solid $gray-lighter;
  }

  .map-body .span8 {
    border-left: 1px solid $gray-lighter;
  }

  .map-body [class*="span"] {
    padding-top: 10px;
  }

  .map-body header span {
    padding-left: 10px;
  }

  .seat-map-legend li {
    line-height: 30px;
    margin-bottom: 5px;
  }

  .seat-map-legend li span {
    @extend .pull-left;
    margin-right: 10px;
  }

  .table-seats {
    width: auto;
    @include container-fixed;
  }

  .table-seats td {
    width: 20px;
    text-align: center;
    padding: 2px;
    border: 0;
  }

  .table-seats .row-exit td:first-of-type,
  .table-seats .row-exit td:last-of-type {
    background: transparent;
  }

  .table-seats .row-wing td:first-of-type,
  .table-seats .row-wing td:last-of-type {
    background: $gray-medium;
    width: 20px;
  }

  .table-seats td:first-of-type {
    border-right: 1px solid $gray-lighter;
  }

  .table-seats td:last-of-type {
    border-left: 1px solid $gray-lighter;
  }

  .table-seats thead td:first-of-type,
  .table-seats thead td:last-of-type {
    border: none;
  }

  .table-seats .aisle {
    border: 1px solid $gray-lighter;
    border-width: 0 1px;
  }

  .table-seats thead tr td {
    font-weight: $text-strong-font-weight;
  }

  .legend-exit {
    display: inline-block;
    height: 25px;
    width: 20px;
  }

  .table-seats .row-exit td,
  .legend-exit {
    background: pink;
    background-image: linear-gradient(left top, rgba(255, 255, 255, 0.2) 0%, transparent 25%, rgba(255, 255, 255, 0.2) 50%, transparent 75%, rgba(255, 255, 255, 0.2) 100%);
    background-size: 25% 17%;
  }

  .inline-seat-number {
    vertical-align: inherit;
  }

  .icon-spinner,
  .icon-alert__warning {
    display: inline-block;
  }

  .icon-alert__warning {
    font-size: 240px;
    margin: 0 auto;
    display: block;
  }

  .seat-map-unavailable-container .loading {
    display: block;
    margin-top: 130px;
  }

  .seat-map-unavailable-container h3 {
    margin-top: $line-height-computed;
  }

  .seat-map-unavailable-container .icon-spinner {
    margin-top: 130px;
  }

  // Seat images
  span.air-seat,
  button.air-seat {
    display: block;
    width: 20px;
    height: 25px;
  }

  span.seat-available,
  button.seat-available {
    background: transparent url('#{$assetsPath}/seat_maps/seatmap_avail.png') top left no-repeat;
    &:hover {
      cursor: pointer;
    }
  }

  span.seat-unavailable,
  button.seat-unavailable {
    background: transparent url('#{$assetsPath}/seat_maps/seatmap_unavail.png') top left no-repeat;
  }

  span.seat-selected,
  button.seat-selected {
    background: transparent url('#{$assetsPath}/seat_maps/seatmap_selected.png') top left no-repeat;
  }
}

//Checkout component
.air-seating {
  // Seat images
  span.air-seat,
  button.air-seat {
    display: block;
    width: 20px;
    height: 25px;
  }

  span.seat-available,
  button.seat-available {
    background: transparent url('#{$assetsPath}/seat_maps/seatmap_avail.png') top left no-repeat;
    &:hover {
      cursor: pointer;
    }
  }

  span.seat-unavailable,
  button.seat-unavailable {
    background: transparent url('#{$assetsPath}/seat_maps/seatmap_unavail.png') top left no-repeat;
  }

  span.seat-selected,
  button.seat-selected {
    background: transparent url('#{$assetsPath}/seat_maps/seatmap_selected.png') top left no-repeat;
  }

  .seat-map-legend li {
    line-height: 30px;
    margin-bottom: 5px;

    span {
      @extend .pull-left;
      margin-right: 10px;
    }
  }

  .legend-exit {
    display: inline-block;
    height: 25px;
    width: 20px;
  }

  .table-seats .row-exit td,
  .legend-exit {
    background: pink;
    background-image: linear-gradient(left top, rgba(255, 255, 255, 0.2) 0%, transparent 25%, rgba(255, 255, 255, 0.2) 50%, transparent 75%, rgba(255, 255, 255, 0.2) 100%);
    background-size: 25% 17%;
  }

  .text-strong {
    font-family: $headings-font-family;
  }

  .flight-details-row {
    @include flexbox;
    align-items: center;
    background: $seat-map-tab-header-background-color_handheld;
    color: $seat-map-tab-header-text-color;
    padding: 6px 16px;
  }

  .success-selected-row {
    background: $state-success-bg;
    color: $state-success-text;
    padding: 6px 16px;
  }

  .table-seats-container {
    overflow: auto;
    //width: auto;
  }

  .table-seats {
    background: $seat-map-available-fuselage-bg-color_handheld;
    overflow: auto;

    &.blur td {
      -webkit-filter: blur(8px);
      -moz-filter: blur(3px);
      -o-filter: blur(3px);
      -ms-filter: blur(3px);
      filter: blur(3px);
      opacity: 0.95;

      &.seat-selected.seat-selecting {
        -webkit-filter: blur(0px);
        -moz-filter: blur(0px);
        -o-filter: blur(0px);
        -ms-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
      }
    }

    td {
      border: 1px solid $seat-map-available-fuselage-bg-color_handheld;
      height: 50px;
      text-align: center;
      vertical-align: middle;
      width: 50px;

      &.seat-available {
        background: $seat-map-available-bg-color_handheld;
        color: $seat-map-available-text-color_handheld;
        line-height: $font-size-base;
        min-width: 50px;

        &.hover {
          cursor: pointer;
        }
      }

      &.seat-unavailable {
        background: $seat-map-available-fuselage-bg-color_handheld;
        min-width: 50px;
        pointer-events: none;
        position: relative;
        text-indent: -9999px;

        // this is for the X mark inside the unavailable seat map squares which are a static size
        &::before,
        &::after {
          content: '';
          position: absolute;
          height: 2px;
          width: 50px;
          top: 50%;
          left: 0;
          background: $seat-map-unavailable-cross-bg-color_handheld;
        }

        &::before {
          @include transform(rotate(45deg));
        }

        &::after {
          @include transform(rotate(-45deg));
        }
      }

      &.aircraft-left,
      &.aircraft-right {
        padding: 0;
      }

      &.fuselage-left,
      &.fuselage-right {
        padding: 0;
        width: 0;
      }

      &.seat-selecting {
        background: $seat-map-seat-selecting-bg-color;
        color: $seat-map-seat-selecting-text-color;
        text-indent: 0;
      }

      &.seat-selected {
        background: $seat-map-seat-selected-bg-color;
        color: $seat-map-seat-selected-text-color;
        font-size: 0;

        &:before {
          font-family: "switchflyShopping";
          content: "\e942";
          font-size: $font-size-large;
          vertical-align: middle;
        }

        span {
          display: none;
        }
      }
    }

    tr[class*="row-exit"] {
      .aircraft-left:after,
      .aircraft-right:after {
        font-family: "switchflyShopping";
        color: $seat-map-exit-row-arrow-color;
        display: block;
        font-size: $font-size-large;
        line-height: 40px;
        position: relative;
      }

      .aircraft-left:after {
        content: "\e934";
        right: 0;
        padding-left: 5px;
      }

      .aircraft-right:after {
        content: "\e935";
        left: 0;
        padding-right: 5px;
      }
    }

    .row-wing {
      .aircraft-left,
      .aircraft-right {
        background: #dce;
        border: none;
      }
    }

    .row-wing-first {
      .aircraft-left {
        background-image: linear-gradient(135deg, #fff 0%, #fff 30%, #dce 30%, #dce 100%);
      }

      .aircraft-right {
        background-image: linear-gradient(45deg, #dce 0%, #dce 70%, #fff 70%, #fff 100%)
      }
    }

    .row-wing-last {
      .aircraft-left {
        background-image: linear-gradient(135deg, #dce 0%, #dce 30%, #fff 30%, #fff 100%);
      }

      .aircraft-right {
        background-image: linear-gradient(45deg, #fff 0%, #fff 70%, #dce 70%, #dce 100%)
      }
    }
  }

  .seat-map-container.blur {
    -webkit-filter: blur(20px);
    -moz-filter: blur(15px);
    -o-filter: blur(15px);
    -ms-filter: blur(15px);
    filter: blur(15px);
    opacity: 0.95;
  }

  .seat-map-unavailable-container {
    height: 50px;
    text-align: center;

    .loading:before {
      top: 15px;
    }
  }
}
